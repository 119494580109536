//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import DataLoading from "@/components/Loading/DataLoading";
// import CalendarMixin from "@/mixins/Calendar";
import RepricerMixin from "@/mixins/Repricer";
import HasTokens from "@/components/HasTokensRepricer.vue";
import WarningRepricer from "@/components/Alert/WarningRepricer.vue";
import ScriptsTable from "@/components/Repricer/ScriptsTable.vue";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
import AppSelect from "@/components/AppSelect.vue";
import DataTableActions from "@/components/DataTable/DataTableActions.vue";
export default {
  mixins: [RepricerMixin],
  meta: {
    title: "Анализ и работа с отзывами"
  },
  data() {
    return {
      totalScripts: 0
    };
  },
  computed: {
    tableKey() {
      return `${this.tabs_tables_model.id}`;
    },
    reportSettings() {
      const tokens = this.selectedTokens.map(item => item.id);
      return {
        tokens
      };
    }
  },
  methods: {
    addScript() {
      this.$router.push({
        name: "RepricerScript"
      });
    },
    updateTotal(val) {
      this.totalScripts = val;
    }
  },
  components: {
    MainTitle,
    ReportPage,
    HasTokens,
    WarningRepricer,
    DataLoading,
    ScriptsTable,
    AppSelectMulti,
    AppSelect,
    DataTableActions
  }
};
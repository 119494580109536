//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalRemoveScript from "@/components/modals/ModalRemoveRepricerScript.vue";
import ModalTurnOnConfirm from "@/components/modals/ModalRepricerScriptTurnOnConfirm.vue";
import DataTable from "@/components/DataTable/DataTable";
import SwitchInput from "@/components/SwitchInput.vue";
import { toExportFormat } from "@/utils/export";
export default {
  components: {
    DataTable,
    SwitchInput,
    ModalRemoveScript,
    ModalTurnOnConfirm
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    },
    small: {
      type: Boolean,
      default: false
    },
    freezeSubscription: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      prev: null,
      total: null
    };
  },
  methods: {
    refresh() {
      this.$refs.table.refreshSilent();
    },
    async tableLoader({
      query
    }) {
      if (query.action === "export") {
        query.page_size = 10000;
        query.page = 1;
      }
      let result = await this.$store.dispatch("repricer/getScripts", {
        ...query,
        ...this.reportSettings
      });
      if (query.action === "export") {
        return toExportFormat(result, this.columns);
      } else {
        this.$emit("updateTotal", result.totals.total);
        this.total = result.totals.total;
        result = {
          items: [...result.data],
          total: result.totals.total,
          success: result.success,
          message: result.message
        };
      }
      return result;
    },
    async updateScript(item, value) {
      await this.$store.dispatch("repricer/updateScript", {
        ...item,
        active: value,
        script_value: JSON.parse(item.script_value)
      });
    },
    async changeStatus(item, data) {
      if (data !== null && data !== void 0 && data.value) {
        this.$refs.modalTurnOnConfirm.open({
          onComplete: async () => {
            data.confirm();
          }
        });
      } else {
        this.updateScript(item, false);
      }
    }
  },
  computed: {
    columns() {
      return [{
        title: "Статус",
        position: 1,
        show: true,
        name: "active",
        width: 50,
        sort: false,
        type: "slot",
        filter: "text"
      }, {
        title: "Название",
        position: 2,
        show: true,
        name: "name",
        width: 300,
        filter: "text"
      }, {
        title: "Тип",
        position: 3,
        show: true,
        name: "script_type_name",
        type: "text",
        width: 160,
        filter: "text"
      }, {
        title: "Дата запуска сценария",
        position: 4,
        show: true,
        name: "last_change_date",
        type: "datetimeLocal",
        width: 160,
        filter: "dateRange"
      }, {
        title: "Товаров",
        position: 5,
        show: true,
        name: "quantity_product",
        width: 160,
        filter: "numberRange"
      }, {
        title: "Магазин",
        position: 6,
        show: true,
        name: "store_name",
        width: 160,
        filter: "text"
      }, {
        title: "",
        position: 7,
        show: true,
        name: "_actions",
        type: "actions",
        actions: [{
          title: "Удалить",
          action: (item, {
            refresh
          }) => {
            this.$refs.modalRemoveScript.open({
              item,
              onComplete() {
                refresh();
              }
            });
          },
          disabled: this.freezeSubscription
        }, {
          title: "Редактировать",
          action: item => {
            this.$router.push({
              name: "RepricerScript",
              params: {
                id: item.id
              }
            });
          },
          disabled: this.freezeSubscription
        }],
        width: 40
      }];
    }
  }
};